

const TokenAllocation = () => {
    return (
        <section id="TokenAllocation">
            <div className="container">
                <div className="teamallocation-container">
                    <div className="TokenAllocationheading-container">
                        {/*<h2>TOKEN ALLOCATION</h2>*/}
                        <h4>More to it than just survival</h4>
                        <p>
                            With each battle, you have the chance to earn valuable crypto rewards.
                        </p>
                    </div>

                    <div className="token_text">
                        <div className="TokenAllocationtext-container">
                            <p><span style={{color: '#F9FD08', fontSize: '22px', fontWeight: "bold"}}>Battle the undead</span></p>
                            <p className="para-text">Dive into intense battles against waves of zombies. Use your hero's powers strategically to survive and conquer.</p>
                        </div>
                        <div className="TokenAllocationtext-container">
                            <p><span style={{color: '#F9FD08', fontSize: '22px', fontWeight: "bold"}}>Earn crypto rewards</span></p>
                            <p className="para-text">Here's where the magic happens. As you progress through the game and achieve various milestones, you'll earn cryptocurrency rewards.</p>
                        </div>
                        <div className="TokenAllocationtext-container">
                            <p><span style={{color: '#F9FD08', fontSize: '22px', fontWeight: "bold"}}>Let it burn</span></p>
                            <p className="para-text">Help CZ and burn tokens.</p>
                        </div>
                        <div className="TokenAllocationtext-container">
                            <p><span style={{color: '#F9FD08', fontSize: '22px', fontWeight: "bold"}}>Trade and upgrade</span></p>
                            <p className="para-text">Trade your hard-earned crypto rewards for in-game items, enhancements, or even more powerful heroes. Stay ahead of the game by constantly upgrading your arsenal.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TokenAllocation;