import Fade from 'react-reveal/Fade';

const Data = [
    {
        heading: '1',
        content: [
            'Grand Unveiling of Website',
            'Game Development',
            'Social Media Development',
            'First Listing on CEX ',
            'Game Release Mobile Version',
        ]
    },
    {
        heading: '2',
        content: [
            'Trading Tournaments',
            'Airdrop',
            'Coinmarketcap/CoinGecko Listing ',
            '2000+ Holders & Community Gamers',
            'NFT Preview',
            'Exploring Partnerships with Influencers',
        ]
    },
    {
        heading: '3',
        content: [
            'More Exchange Listings ',
            'NFT Launch',
            '25,000+ Community Gamers & Holders',
            '2nd Airdrop Marketing Campaign ',
            'Continuous Progress',
        ]
    }
];

const convertToRoman = (num) => {
    const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII"];
    return romanNumerals[num - 1] || num;
};

const Roadmap = () => {
    return (
        <section id="roadmap">
            <div className="container">
                <div className="roadmaptext-container">
                    <Fade top>
                        <h2>ROADMAP</h2>
                    </Fade>
                </div>

                <div className="roadmap-mainContainer_first">
                    <Fade left>
                        {Data.map((item, index) => (
                            <div key={index} className="outer-inline-div">
                                <center>
                                    <h2>Phase {convertToRoman(item.heading)}</h2>
                                </center>

                                {/*<span className="roadmap-desc"><p>{item.desc}</p></span>*/}

                                <p>
                                    {Array.isArray(item.content)
                                        ? item.content.map((point, i) => <span key={i}>{point}<br /></span>)
                                        : item.content}
                                </p>
                            </div>
                        ))}
                    </Fade>
                </div>
            </div>
        </section>
    )
}

export default Roadmap;