import image1 from '../images/gametrailer.png';

const GameTrailer = () => {
    return (
        <section id="game-trailer">
            <div className="container">
                <div className="main-game-trailer">
                    <div className="game-trailer-title">
                        <h2>GAME TRAILER</h2>
                    </div>

                    <div className="game-image">
                        <img src={image1} alt=""/>
                        {/*<video className="lazy" loop autoPlay muted src={video} />*/}

                        <div className="game">
                            <div className="game-btn">
                                <a href={{}} target="_blank" rel="noopener noreferrer"><button>Coming soon</button></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GameTrailer;