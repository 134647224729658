
const CopyRights = () => {

    const currentYear = new Date().getFullYear()
    
    return (
        <section id="CopyRights">
            <div className="container">
                <div className="CopyRights-container">
                    <div className="title">
                        <p>&copy; {currentYear} All rights reserved to The CZ Battle</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CopyRights;