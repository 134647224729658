

const Disclaimer = () => {
    return (
        <section id="Disclaimer">
            <div className="container">
                <div className="Disclaimertext-container">
                    <h2>Join The Battle Today!</h2>
                    <p>
                        Are you ready to become a legendary hero, help CZ fight, and earn cryptocurrency rewards
                        in the process? Don't miss your chance to be a part of THE CZ BATTLE revolution. It's
                        time to turn your gaming skills into crypto riches!
                    </p>
                </div>

                <div className="Disclaimertext-container">
                    <h2>Dare To Start?</h2>
                    <p>
                        Jump into the most thrilling and addictive crypto arcade game ever.
                    </p>
                </div>

                <div className="disclaimer-buy">
                    <div className="disclaimer-btn">
                        <a href='https://www.bitmart.com/en-US' target="_blank" rel="noopener noreferrer"><button>BUY "theCZbattle"</button></a>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default Disclaimer;