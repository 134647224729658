import './App.css';
import './Responsive.css';
import Banner from './components/Banner';
import GameTrailer from './components/Game_trailer';
/*import Header from './components/Header';*/
import Roadmap from './components/Roadmap';
import Vision from './components/Vision';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Introduction from './components/Introduction';
import Navigation from './components/Navigation';
import Ecosystem from './components/Ecosystem';
import TokenAllocation from './components/TokenAllocation';
import Disclaimer from './components/Disclaimer';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CopyRights from './components/CopyRights';

function App() {
  return (
    <div className="App">
      <Navigation />
      {/*<Header />*/}
      <Banner />
      <div className='bg'>
        <Introduction />
        <Vision />
        <Ecosystem />
        <TokenAllocation />
        <Roadmap />
        <GameTrailer />
        <Disclaimer />
        <Footer />
        <CopyRights />
      </div>
    </div>
  );
}

export default App;
