import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import Logo from '../assets/images/logo2.png';
import pdf from '../whitepaper/zombie_whitepaper.pdf';


const Navigation = () => {
    return (
        <div className='container'>
            <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top" className="navbarBg">
                <Container className="logo-className">
                    <Navbar.Brand href="#" id="logo-heading">{/*<img src={{}} alt='image1'/>*/}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto links">
                            <Nav.Link className="nav-items text-nowrap" href="#header-container">Home</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" href="#vision">Vision</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" href="#roadmap">Roadmap</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" href="#game-trailer">Game Trailer</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" href="#Disclaimer">Join</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" href={pdf} target="_blank" rel="noopener noreferrer">Whitepaper</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                    {/*
                <div className="nav-btn text-nowrap">
                    <a href='#' target="_blank" rel="noopener noreferrer"><button>BUY NOW</button></a>
                </div>
                */}
                </Container>



            </Navbar>
        </div>
    );
}

export default Navigation;