

const Vision = () => {
    return (
        <section id="vision">
            <div className="container">
                <div className="visiontext-container">
                    <div className="vision-btn">
                        <a href="https://www.bitmart.com/en-US" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}><button>Get your "theCZbattle"</button></a>
                    </div>
                    <h2>Ready To Face The Battle</h2>
                    <p>
                        Part of the world is addicted to bringing CZ down. Your mission is to merge, defend, and fight against them.
                        In a world ravaged by people where cities lay in ruins and hope seems
                        all but lost, a group of brave individuals emerges as CZ's last line of defense. It's a
                        battle between a dangerous world and CZ. Ready for a full action-packed and addictive
                        battle strategy game?
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Vision;