

const Introduction = () => {
    return (
        <section id="introduction">
            <div className="container">
                <div className="introtext-container">
                    <h2>The Ultimate Showdown Starts Now</h2>
                    {/*<h4>WELCOME TO THE ZOMBIE BATTLE!</h4>*/}
                    <p>
                        Embark on a hilarious journey with CZ, the crypto maestro, as he faces off against
                        part of the world in the ultimate meme play-to-earn arcade game.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Introduction;