

const Ecosystem = () => {
    return (
        <section id="ecosystem">
            <div className="container">
                <div className="ecosystemtext-container">
                    <h2>THE CZ BATTLE Is Not Just Another Crypto Game</h2>
                    {/*<h4>NAME</h4>
                    <p>THE ZOMBIE BATTLE (ZOMBIEBATTLE)</p>
                    <h4>CONTRACT ADDRESS</h4>
                    <p><span style={{color: '#00C0E3'}}>AUDIT:</span> Token contract has undergone a rigorous and comprehensive security audit.</p>*/}
                    <p>
                        It's a revolutionary arcade gaming experience that allows you to play, have fun, and earn cryptocurrency 
                        simultaneously. Built on blockchain technology, ensuring transparency and security for all players. 
                        In this immersive world, players take on the role of being CZ's hero.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Ecosystem;