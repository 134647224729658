import image1 from '../images/footer-logo.png';

const Footer = () => {
    return (
        <section id="Footer">
            <div className="container">
                <div className="Footer-container">
                    {/*<h2>THE ZOMBIE BATTLE</h2>*/}
                    <div className='footer-image-container'>
                        <div className='footer-logo'>
                            <img src={image1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;