import video from "../video/header_video.mp4";
import image from "../images/moon.png";
import czlogo from "../images/czlogo.png";

const Banner = () => {
    return (
        <section id="banner">


            <div className="banner-container">
                <div className="wrapper">
                    <video className="lazy" controls={false} playsinline style={{pointerEvents: "none"}} loop autoPlay muted src={video} />
                </div>

                <div className="container">
                    <div className="header-image">
                        <img src={image} alt="" />
                    </div>

                    <div className="text-logo-container">
                        <div className="wrapper-glichtext">
                            <h1 className="glitch" data-text="THE CZ BATTLE">THE CZ BATTLE</h1>
                        </div>
                        <div className="cz-logo-container">
                            <div className="cz-logo">
                                <img src={czlogo} alt="czlogo" />
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                <div className="container">
                    <div className="text-container">
                        <div className="text">
                            <h2>THE ZOMBIE BATTLE</h2>
                        </div>
                    </div>
                </div>
*/}
            </div>
        </section>
    )
}

export default Banner;